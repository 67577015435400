<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
    <div class="m-2 pa-2">
        <bread-crumbs :items="items"></bread-crumbs>
        
        <tabs-comp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
            <v-row>
                
                <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    class="mt-4"
                    >
                    <b-input-group>
                        <b-form-input
                            v-model="search"
                            append-html=""
                            :placeholder="lang.search"
                            single-line
                            hide-details
                            class="inborder"
                        ></b-form-input>
                        <b-input-group-prepend>
                            <span class="input-group-text" 
                            :style="`background:gold !important;padding: 10px 16px;border-radius: 0;border: 1px solid #000;border-`+lang.algin+`: 0;`">
                            <i class='fas fa-search'></i></span>
                        </b-input-group-prepend>
                    </b-input-group>
                </v-col>
                <v-col
                    cols="12" md="9" sm="12" class="text-end">
                    <b-button
                    v-b-toggle.add_customer
                    class="btn-sm btn btn-success"
                    style="margin-top:11px;width:150px;background:transparent;border:none;color:#000"
                    id="multiply-button"
                    >
                    <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_customer}}</div>
                    </b-button>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" :style="`direction:`+ lang.dir + `;`">
                    <v-data-table
                        :headers="headers"
                        :items="tablerows"
                        :search="search"
                        :items-per-page="15"
                        :page.sync="page"
                        width="100%"
                        id="MainTable"
                        hide-default-footer
                        @page-count="pageCount = $event" 
                        fixed-header
                    >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:100px;text-align:center;">{{ row.item.customerid }}</td>
                            <td style="width:100px;text-align:center;">{{ row.item.mobile1 }}</td>
                            <td style="width:100px;text-align:center;">{{ row.item.mobile2 }}</td>
                            <td style="text-align:center;">{{ row.item.full_name }}</td>
                            <td style="text-align:center;" nowrap>{{ row.item.company_name }}</td>
                            <td style="text-align:center;">{{ row.item.company_vatid }}</td>
                            <td style="text-align:center;width:400px;padding:5px 2px !important;">
                                <v-btn class="btn-sm btn-primary" v-if="$store.state.licenseType.quotation" style="font-size:0.2em;background:darkblue;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="quotOP(row.item)" v-b-toggle.add_quotation>{{lang.quotations}}</v-btn>
                                <v-btn class="btn-sm btn-danger" style="font-size:0.2em;background:darkred;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="getStatement(row.item)" v-b-toggle.clientst_ids>{{lang.client_statement}}</v-btn>
                                <v-btn class="btn-sm btn-danger" style="font-size:0.2em;background:green;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="InvOP(row.item)" v-b-toggle.add_invoice>{{lang.add_invoice}}</v-btn>
                                <v-btn class="btn-sm btn-secandory" style="font-size:0.2em;background:#000;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="upCusto(row.item)" v-b-toggle.update_customer>{{lang.update}}</v-btn>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                
            </v-col>
            <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">
                <v-container>
                    <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        value="15"
                        :length="pageCount"
                    ></v-pagination>
                    </div>
                </v-container>
                </v-col>
            </v-row>
        </v-row>
        <add-customer />
        <add-quotation ref="addQoutFloat" />
        <add-invoice ref="addinvoices" />
        <update-customer ref="updatecustomer" />
        <client-statements ref="clientStatments" />
    </div>
        <Footer />
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue'
import axios from 'axios'
import AddCustomer from '@/components/addCustomer.vue'
import AddQuotation from '@/components/addQuotation.vue'
import AddInvoice from '@/components/addInvoice.vue'
import TabsComp from '@/components/tabsComp.vue'
import UpdateCustomer from '@/components/update-customer.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import ClientStatements from '@/components/clientStatements.vue'
import ExportsPrint from '@/components/exports-print.vue'
export default{
    name: 'clientlist',
    components: {BreadCrumbs, AddCustomer,AddQuotation, AddInvoice, TabsComp,UpdateCustomer, HeaderPortrate,Footer, ClientStatements, ExportsPrint},
    data() {
        return {
            openQuot: false,
            
            page: 1,
            pageCount: 0,
            search: '',
            tablerows: [
               
            ],
            opselected: {},
            ifpselected: {},
            invorquot: 0,
            tab1: 1,
            tab2: 2,
            tab3: 3,
            ccustomer: {}
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return  {
                text: this.lang.customers,
                disabled: true,
                href: '/clientList',
            };
        },
        currentCus: function() {
            if(this.invorquot == 1){
                return {
                    id: this.opselected.id,
                    full_name: this.opselected.full_name,
                    mobile: this.opselected.mobile1,
                    company: {
                        fullname: this.opselected.company_name,
                        vatid: this.opselected.company_vatid,
                    }
                };
            }else{
                return {
                    id: this.ifpselected.id,
                    full_name: this.ifpselected.full_name,
                    mobile: this.ifpselected.mobile1,
                    company: {
                        fullname: this.ifpselected.company_name,
                        vatid: this.ifpselected.company_vatid,
                    }
                };
            }
        },
        headers: function() {
            let headers = [
                { 
                    text: this.lang.customerid, align: 'center',
                    filterable: true, value: 'customerid',
                    sortable: false,
                },
                { 
                    text: this.lang.mobile, align: 'center',
                    filterable: true, value: 'mobile',
                    sortable: false,
                },
                { 
                    text: this.lang.additional_mobile, align: 'center',
                    filterable: true, value: 'mobile1',
                    sortable: false,
                },
                { 
                    text: this.lang.customer_name, align: 'center',
                    filterable: false, value: 'full_name',
                    sortable: true,
                },
                { 
                    text: this.lang.company_name, align: 'center',
                    filterable: true, value: 'company_name',
                    sortable: false,
                },
                { 
                    text: this.lang.company_vatid, align: 'center',
                    filterable: true, value: 'vatid',
                    sortable: false,
                },
                { 
                    text: this.lang.action, align: 'center',
                    filterable: false,
                    sortable: false,
                },
            ];
            return headers;
        },
        tabs: function(){
            let t = [
                
                ]
            if(this.$store.state.licenseType.crm){
                t.push({
                        index:6,name:this.lang.projects,href:'/customers',class:'mytab4',status:1
                    })
            }
            t.push({
                index:0,name:this.lang.customers,href:'/clientList',class:'mytab',status:1
            })
            t.push({
                index:5,name:this.lang.invoices,href:'/invoicelist',class:'mytab3',status:1
            })
            if(this.$store.state.licenseType.quotation){
                t.push({
                    index:1,name:this.lang.new_quotations,href:'/quotations/n/1',class:'mytab3',status:1
                })
                t.push({
                    index:2,name:this.lang.invoiced_quots,href:'/quotations/i/2',class:'mytab3',status:2
                })
                t.push({
                    index:3,name:this.lang.canceled_quots,href:'/quotations/c/3',class:'mytab3',status:3
                })
            }
            if(this.$store.state.licenseType.families){
                t.push({
                    index:3,name:this.lang.sent_requests,href:'/vendor-sents',class:'mytab3',status:3
                })
                t.push({
                    index:3,name:this.lang.payment_requests,href:'/vendor-prequests',class:'mytab3',status:3
                })
            }
            return t;
        }
    },
    methods: {
        preparedata(){
            let t = this.headers;
            let data = this.tablerows;
            let hdata = [];
            let exdata = [];
            for(let i=0;i<t.length;i++){
                hdata.push(t[i].text);
            }
            exdata.push(hdata);
            for(let k=0;k<data.length;k++){
            let x = [];

                x.push(data[k].customerid);
                x.push(data[k].mobile1);
                x.push(data[k].mobile2);
                x.push(data[k].full_name);
                x.push(data[k].company_name);
                x.push(data[k].company_vatid);
                
                exdata.push(x);
            }
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = '';
            this.$refs.exportbar.edate = '';
            
            this.$refs.exportbar.exporttitle = this.lang.customers;
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        getCurrentCards(){
            //
        },
        upCusto(item){
            this.$refs.updatecustomer.customers = item;
            this.$refs.updatecustomer.customers.mobile = item.mobile1;
            this.$refs.updatecustomer.customers.mobile1 = item.mobile2;
        },
        quotOP(item){
            this.$refs.addQoutFloat.company = {
                full_name: item.fullname,
                mobile: item.mobile
            };
            this.$refs.addQoutFloat.customer.id = item.id;
            this.$refs.addQoutFloat.customer.mobile = item.mobile1;
            this.$refs.addQoutFloat.customer.full_name = item.full_name;
            this.$refs.addQoutFloat.company.fullname = item.company_name;
            this.$refs.addQoutFloat.company.vatid = item.company_vatid;
        },
        InvOP(item){
            this.$refs.addinvoices.customer.mobile = item.mobile1;
            this.$refs.addinvoices.getName();
            
            if(item.company_name != ''){
                this.$refs.addinvoices.customer.cctype = 2;
                this.$refs.addinvoices.changeCtype();
            }else{
                this.$refs.addinvoices.customer.cctype = 1;
                this.$refs.addinvoices.changeCtype();
            }
        },
        getClients() {
            const post = new FormData();
            post.append('type',"getClients");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]",1);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                // console.log(response.data);
                this.tablerows = response.data.results.data;
            })
        },
        getStatement(item){
            // this.$router.push({path: '/client-statement/'+item.id})
            let classid = parseFloat(item.customerid);
            if(parseFloat(item.customerid) < 6000){
                classid = parseFloat(item.customerid) + parseFloat(6000);
            }
            this.$refs.clientStatments.accountn.full_name = item.full_name;
            this.$refs.clientStatments.accountn.customerid = classid;
            this.$refs.clientStatments.getReport();
        }
    },
    created() {
        this.getClients();
    },
}
</script>